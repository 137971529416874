import React from "react";
import "./Home.css";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { Button } from "./Button";

function Home() {
  return (
    <div className="header-container">
      <h1>WELKOM</h1>
      <p>Café Bar de Welp</p>
      <div className="header-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          Menukaart <FastfoodIcon className="food-icon"></FastfoodIcon>
        </Button>
      </div>
    </div>
  );
}

export default Home;
