import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import "./Navbar.css";
import Logo from "../images/logo.png";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={Logo} alt="Logo" /> Café Bar de Welp
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            {click ? (
              <CloseIcon style={{ color: "white" }} />
            ) : (
              <MenuIcon style={{ color: "white" }} />
            )}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/cafetaria"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Cafetaria
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/verhuur"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Verhuur
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/vergaderruimtes"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Vergaderruimtes
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/historie"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Historie
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/overons"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Over ons
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
