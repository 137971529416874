import "./HistoryScreen.css";
import image1 from "../images/historie1.jpg";
import image2 from "../images/historie2.JPG";
import image3 from "../images/historie3.JPG";
import image4 from "../images/historie4.JPG";

// Components
import Footer from "../components/Footer";

function HistoryScreen() {
  return (
    <>
      <div className="history__container">
        <p className="history__title">Historie</p>
        <img src={image1} alt="historie1"></img>
        <img src={image2} alt="historie2"></img>
        <img src={image3} alt="historie3"></img>
        <img src={image4} alt="historie4"></img>
      </div>
      <Footer />
    </>
  );
}

export default HistoryScreen;
