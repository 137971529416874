import "./AboutScreen.css";
import image from "../images/about.jpg";

// Components
import Footer from "../components/Footer";

function AboutScreen() {
  return (
    <>
      <div className="about__container">
        <p className="about__title">Over ons</p>
        <p className="about__text">
          Welkom bij Café bar de Welp! Wij zijn Jeroen en Lutske van Dijk,
          eigenaren van het enige café in Reduzum. Kom gezellig een biertje
          drinken, biljarten, voetbal kijken of vier uw verjaardag of bruiloft
          bij ons. Groepen tot 300 mensen zijn welkom, we maken er altijd een
          feestje van!
        </p>
        <img src={image} alt="about"></img>
      </div>
      <Footer />
    </>
  );
}

export default AboutScreen;
