import "./RoomsScreen.css";
import image1 from "../images/ruimte01.jpg";
import image2 from "../images/ruimte02.jpg";
import image3 from "../images/ruimte03.jpg";
import image4 from "../images/ruimte04.jpg";

// Components
import Footer from "../components/Footer";

function RoomsScreen() {
  return (
    <>
      <div className="rooms__container">
        <p className="rooms__title">Vergaderruimtes</p>
        <p className="rooms__text">
          Bij Café bar de Welp vindt u verschillende mogelijkheden om te
          vergaderen. Onze ruimtes zijn geschikt voor 5 tot wel 70 personen.
          Daarbij zijn er verschillende mogelijkheden: een bakje koffie of thee
          met een koek, een goed verzorgde lunch tussen de middag, alles is
          mogelijk bij Café bar de Welp. Naast vergaderingen zijn onze ruimtes
          natuurlijk ook geschikt voor feestjes of andere gelegenheden. Heeft u
          interesse? Neem dan contact met{" "}
          <a href="mailto:info@cafebardewelp.nl">Jeroen</a>.
        </p>
        <img src={image4} alt="ruimte4"></img>
        <img src={image3} alt="ruimte3"></img>
        <img src={image1} alt="ruimte1"></img>
        <img src={image2} alt="ruimte2"></img>
      </div>
      <Footer />
    </>
  );
}

export default RoomsScreen;
