import React from "react";
import "./CafetariaScreen.css";

import image1 from "../images/menu1.jpg";
import image2 from "../images/menu2.jpg";

// Components
import Footer from "../components/Footer";

function CafetariaScreen() {
  return (
    <>
      <div className="cafetaria__container">
        <p className="cafetaria__title">Cafetaria</p>
        <p className="cafetaria__text">
          Zin in een kapsalon, een burger van de bakplaat, een plate schnitzel
          of gewoon een lekker patatje? Hieronder ziet u de menukaart van
          Cafetaria de Welp. Maak uw keuze en bestel door te bellen naar 0566
          601541 of stuur een WhatsAppje naar 06 57676586. Eet smakelijk!
        </p>
        <img src={image1} alt="about"></img>
        <img src={image2} alt="about"></img>
      </div>
      <Footer />
    </>
  );
}

export default CafetariaScreen;
