import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import IconButton from "@material-ui/core/IconButton";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function Footer() {
  const mapsLink = `https://www.google.com/maps/search/?api=1&query=cafe+bar+de+welp+reduzum`;
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Openingstijden</h2>
            <p>Maandag: Gesloten </p>
            <p>Dinsdag: Gesloten </p>
            <p>Woensdag: Gesloten </p>
            <p>Donderdag: 17:00 - 00:00 </p>
            <p>Vrijdag: 17:00 - 00:00 </p>
            <p>Zaterdag: 17:00 - 00:00 </p>
            <p>Zondag: 17:00 - 00:00 </p>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Contact</h2>
            <a href={mapsLink}>Haedstrjitte 14</a>
            <a href={mapsLink}>9008 SP Reduzum</a>
            <p>info@cafebardewelp.nl</p>
            <p>0566-601541</p>
            <p>06-51525180</p>
            <p></p>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <Link
              to={{ pathname: "https:www.facebook.com/deWelp/" }}
              target="_blank"
            >
              Facebook
            </Link>
            <Link
              to={{ pathname: "https://www.instagram.com/cafebardewelp_/" }}
              target="_blank"
            >
              Instagram
            </Link>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <h4 className="website-rights">© 2022 Robin Sinnema</h4>
          <IconButton
            onClick={(event) =>
              (window.location.href =
                "https://www.linkedin.com/in/robin-sinnema-127b291b8/")
            }
          >
            <LinkedInIcon style={{ color: "white" }} />
          </IconButton>
        </div>
      </section>
    </div>
  );
}

export default Footer;
