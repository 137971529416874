import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Navbar from "./components/Navbar";

// Screens
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import HistoryScreen from "./screens/HistoryScreen";
import RoomsScreen from "./screens/RoomsScreen";
import VerhuurScreen from "./screens/VerhuurScreen";
import CafetariaScreen from "./screens/CafetariaScreen";

function App() {
  return (
    <Router>
      <Navbar />
      <main>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/overons" component={AboutScreen} />
          <Route exact path="/historie" component={HistoryScreen} />
          <Route exact path="/vergaderruimtes" component={RoomsScreen} />
          <Route exact path="/verhuur" component={VerhuurScreen} />
          <Route exact path="/cafetaria" component={CafetariaScreen} />
        </Switch>
      </main>
    </Router>
  );
}

export default App;
