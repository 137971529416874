import React from "react";
import Home from "../components/Home";
import Footer from "../components/Footer";

function HomeScreen() {
  return (
    <>
      <Home />
      <Footer />
    </>
  );
}

export default HomeScreen;
