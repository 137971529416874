import React, { useState } from "react";
import "./VerhuurScreen.css";
import Button from "@material-ui/core/Button";
import image_biertafel from "../images/verhuur_biertafel.jpg";
import image_kopschotel from "../images/verhuur_kopschotel.jpg";
import image_gebaksschotel from "../images/verhuur_gebaksschotel.jpg";
import image_glaswerk from "../images/verhuur_glaswerk.jpg";
import image_borden from "../images/verhuur_borden.jpg";
import image_geluid from "../images/verhuur_geluid.jpg";
import image_bierbox from "../images/verhuur_bierbox.jpg";
import image_statafel from "../images/verhuur_statafel.png";

// Components
import Footer from "../components/Footer";

function VerhuurScreen() {
  const [onClickNumber, setOnClickNumber] = useState(0);
  const onClickHandler = (id) => {
    setOnClickNumber(id);
  };

  return (
    <>
      <div className="verhuur__container">
        <p className="verhuur__title">Verhuur</p>
        <p className="verhuur__text">
          Thuis een gelegenheid maar niet genoeg tafels, banken, servies of
          glaswerk? Bij Café bar de Welp hebben we een ruime selectie aan
          verhuurbare items! U kunt alles hieronder voor een klein bedrag per
          dag huren. Vragen of interesse? Neem gerust contact op met{" "}
          <a href="mailto:info@cafebardewelp.nl">Jeroen</a>.
        </p>
        <div className="verhuur__item">
          <p className="verhuur__item__naam">Statafel</p>
          <p className="verhuur__item__prijs">€7,50 (per dag)</p>
          <Button
            variant="contained"
            className="verhuur__btn"
            onClick={() => onClickHandler(1)}
          >
            Afbeelding bekijken
          </Button>
          {onClickNumber !== 1 ? (
            <p></p>
          ) : (
            <img src={image_statafel} alt="statafel"></img>
          )}
        </div>

        <div className="verhuur__item">
          <p className="verhuur__item__naam">Biertafel + 2 banken</p>
          <p className="verhuur__item__prijs">€10,- (per dag)</p>
          <Button
            variant="contained"
            className="verhuur__btn"
            onClick={() => onClickHandler(2)}
          >
            Afbeelding bekijken
          </Button>
          {onClickNumber !== 2 ? (
            <p></p>
          ) : (
            <img src={image_biertafel} alt="biertafel"></img>
          )}
        </div>

        <div className="verhuur__item">
          <p className="verhuur__item__naam">Podium met zwarte tegels (2x1m)</p>
          <p className="verhuur__item__prijs">€15,- (per dag)</p>
        </div>

        <div className="verhuur__item">
          <p className="verhuur__item__naam">Kop en Schotel + lepeltje</p>
          <p className="verhuur__item__prijs">€0,35 (per dag)</p>
          <Button
            variant="contained"
            className="verhuur__btn"
            onClick={() => onClickHandler(4)}
          >
            Afbeelding bekijken
          </Button>
          {onClickNumber !== 4 ? (
            <p></p>
          ) : (
            <img src={image_kopschotel} alt="kopschotel"></img>
          )}
        </div>

        <div className="verhuur__item">
          <p className="verhuur__item__naam">Gebaksschotel + vorkje</p>
          <p className="verhuur__item__prijs">€0,35 (per dag)</p>
          <Button
            variant="contained"
            className="verhuur__btn"
            onClick={() => onClickHandler(5)}
          >
            Afbeelding bekijken
          </Button>
          {onClickNumber !== 5 ? (
            <p></p>
          ) : (
            <img src={image_gebaksschotel} alt="gebaksschotel"></img>
          )}
        </div>

        <div className="verhuur__item">
          <p className="verhuur__item__naam">Glaswerk</p>
          <p className="verhuur__item__prijs">€0,55 (per dag)</p>
          <Button
            variant="contained"
            className="verhuur__btn"
            onClick={() => onClickHandler(6)}
          >
            Afbeelding bekijken
          </Button>
          {onClickNumber !== 6 ? (
            <p></p>
          ) : (
            <img src={image_glaswerk} alt="glaswerk"></img>
          )}
        </div>

        <div className="verhuur__item">
          <p className="verhuur__item__naam">Borden</p>
          <p className="verhuur__item__prijs">€1,00 (per dag)</p>
          <Button
            variant="contained"
            className="verhuur__btn"
            onClick={() => onClickHandler(7)}
          >
            Afbeelding bekijken
          </Button>
          {onClickNumber !== 7 ? (
            <p></p>
          ) : (
            <img src={image_borden} alt="borden"></img>
          )}
        </div>

        <div className="verhuur__item">
          <p className="verhuur__item__naam">
            Geluid dB Technologies ES602 (2x)
          </p>
          <p className="verhuur__item__prijs">€20,00 (per dag)</p>
          <Button
            variant="contained"
            className="verhuur__btn"
            onClick={() => onClickHandler(8)}
          >
            Afbeelding bekijken
          </Button>
          {onClickNumber !== 8 ? (
            <p></p>
          ) : (
            <img src={image_geluid} alt="geluid"></img>
          )}
        </div>

        <div className="verhuur__item">
          <p className="verhuur__item__naam">Bierbox</p>
          <p className="verhuur__item__prijs">€30,-</p>
          <Button
            variant="contained"
            className="verhuur__btn"
            onClick={() => onClickHandler(9)}
          >
            Afbeelding bekijken
          </Button>
          {onClickNumber !== 9 ? (
            <p></p>
          ) : (
            <img src={image_bierbox} alt="bierbox"></img>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VerhuurScreen;
